import * as React from "react"
import { Link, navigate, graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { linkResolver } from "../utils/linkResolver"
import { langs } from "../../prismic-configuration.js"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CardBlogPost from "../components/cardBlogPost"
import SocialIcons from "../components/socialIcons"

const BlogPost = ({ data, pageContext }) => {
  if (!data) return null
  const p = data.prismicBlogPost.data || {}
  const { category_name = "News", category_color = "#FFBF04" } =
    p.category?.document?.data || {}

  const components = {
    heading2: ({ children }) => (
      <h2 className="text-xl text-neutral-600 font-extrabold uppercase mt-4 mb-1">
        {children}
      </h2>
    ),
    heading3: ({ children }) => (
      <h3 className="text-base text-neutral-600 font-extrabold  uppercase mt-4 mb-1">
        {children}
      </h3>
    ),
    heading4: ({ children }) => (
      <h4 className="text-base text-neutral-500 font-extrabold  uppercase mt-4 mb-1">
        {children}
      </h4>
    ),
    heading5: ({ children }) => (
      <h5 className="text-base text-neutral-400 font-extrabold  uppercase mt-4 mb-1">
        {children}
      </h5>
    ),
    paragraph: ({ children }) => <p className="text-base pt-2">{children}</p>,
    list: ({ children, key }) => (
      <ul
        key={key}
        className="text-base mt-4 mb-1 ml-10"
        style={{ listStyle: "inherit" }}
      >
        {children}
      </ul>
    ),
    oList: ({ children, key }) => (
      <ol
        key={key}
        className="text-base mt-4 mb-1 ml-10"
        style={{ listStyle: "normal" }}
      >
        {children}
      </ol>
    ),
    hyperlink: ({ node, children }) => {
      const target = node.data.target
        ? `target="${node.data.target}" rel="noopener"`
        : ""
      if (node.data.type) {
        // internal Link
        const url = linkResolver(node.data, pageContext.webview)
        return (
          <Link
            to={url}
            className="bg-pocket-yellow font rounded-sm  px-1 text-white  "
          >
            {children}
          </Link>
        )
      } else {
        // external link
        return (
          <a
            href={node.data.url}
            target={target}
            rel="noreferrer"
            className="text-neutral-600 underline"
          >
            {children}
          </a>
        )
      }
    },
    image: ({ node }) => (
      <div className="flex flex-col justify-center items-center">
        <img src={node.url} alt={node.alt ? node.alt : ""} className="" />
        <p className="m-auto text-center text-xs">{node.alt}</p>
      </div>
    ),
  }
  return (
    <Layout
      lang={pageContext.lang}
      globals={data.prismicGlobals}
      webview={pageContext.webview}
    >
      <div
        className={`container px-8 mx-auto ${
          !pageContext.webview ? "pb-20" : "pb-10"
        }  flex flex-col md:flex-row lg:px-32`}
      >
        <section className={pageContext.webview ? "md:w-full" : " md:w-4/6 "}>
          {pageContext.webview && (
            <button
              onClick={() => navigate(-1)}
              className={`text-sm rounded-md px-6 py-2 mb-4 font-montserrat font-extrabold uppercase  text-white`}
              style={{ background: category_color }}
            >
              {" "}
              Back
            </button>
          )}
          <div className="flex items-center justify-between mb-3 md:mt-5 md:mb-5">
            <div
              className={`font-montserrat font-extrabold text-base uppercase md:text-3xl `}
              dangerouslySetInnerHTML={{
                __html: p.title.html,
              }}
            />
            {/* <time
                dateTime={data.prismicBlogPost.first_publication_date}
                className="ml-5 text-xs font-montserrat font-extrabold text-neutral-400 md:text-base"
              >
                {data.prismicBlogPost.first_publication_date_en}
              </time> */}
          </div>

          <GatsbyImage
            image={getImage(p.cover)}
            alt={p.cover.alt || ""}
            layout={"fullWidth"}
            className={`w-full rounded-t-xl lg:flex-1 `}
          />
          <div
            className={`text-base rounded-b-xl px-6 py-2 font-montserrat font-extrabold uppercase text-white`}
            style={{ background: category_color }}
          >
            {category_name}
          </div>
          <div className="mt-10 text-neutral-500 md:px-10" css={cssRichText}>
            <PrismicRichText
              field={p.structured_text.richText}
              components={components}
            />
          </div>
        </section>

        {!pageContext.webview && (
          <section className=" mt-8   md:px-8 md:w-2/6 md:pt-16 md:mt-5">
            {p.related[0]?.post_related.document &&
              p.related.map((post, i) => {
                return (
                  <CardBlogPost
                    key={i}
                    post={post.post_related.document}
                    isLarge={false}
                    globals={data.prismicGlobals}
                    publication_date={{
                      en: post.post_related.document
                        .related_first_publication_date_en,
                      fr: post.post_related.document
                        .related_first_publication_date_fr,
                    }}
                    webview={pageContext.webview}
                  />
                )
              })}
            <div className="hidden justify-center content-center mt-5   md:flex">
              <SocialIcons isDark={true} />
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
}

const cssRichText = css`
  iframe {
    width: 100%;
    height: 200px;
    @media (min-width: 600px) {
      height: 350px;
    }
    @media (min-width: 800px) {
      height: 400px;
    }
  }
`

export const query = graphql`
  query blogPostQuery($id: String, $lang: String) {
    prismicBlogPost(id: { eq: $id }) {
      uid
      lang
      data {
        seo_title
        seo_description
        seo_og_image {
          url
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        cover {
          gatsbyImageData
          alt
          url
        }
        publishing_order
        structured_text {
          richText
        }
        category {
          document {
            ... on PrismicArticleCategory {
              data {
                category_name
                category_color
              }
            }
          }
        }
        related {
          post_related {
            document {
              __typename
              ... on PrismicBlogPost {
                url
                id
                uid
                lang
                data {
                  type
                  title {
                    text
                  }
                  cover {
                    gatsbyImageData
                    alt
                  }
                  category {
                    document {
                      ... on PrismicArticleCategory {
                        data {
                          category_name
                          category_color
                        }
                      }
                    }
                  }
                }
                related_first_publication_date_en: first_publication_date(
                  locale: "en-us"
                  formatString: "MMM DD, YYYY"
                )
                related_first_publication_date_fr: first_publication_date(
                  locale: "fr-fr"
                  formatString: "DD MMM, YYYY"
                )
              }
            }
          }
        }
        type
      }
      first_publication_date
      first_publication_date_en: first_publication_date(
        locale: "en-us"
        formatString: "MMM DD, YYYY"
      )
      first_publication_date_fr: first_publication_date(
        locale: "fr-fr"
        formatString: "DD MMM, YYYY"
      )

      alternate_languages {
        id
        uid
        lang
      }
    }
    prismicGlobals(lang: { eq: $lang }) {
      data {
        follow_us
        read_more
        view_all
        footer_title
        footer_subtitle
        copyright
        store {
          link
          store_id
        }
        social {
          link
          social_id
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data, pageContext }) => {
  const { siteUrl } = data.site.siteMetadata
  // let alternatePosts = []
  // data.prismicBlogPost.alternate_languages.map()
  const pagePath = data.prismicBlogPost.uid
  const isNoIndex =
    pageContext.webview || !data.prismicBlogPost.data.publishing_order

  return (
    <Seo
      title={
        data.prismicBlogPost.data.seo_title ||
        data.prismicBlogPost.data.title.text
      }
      description={data.prismicBlogPost.data.seo_description}
    >
      {isNoIndex && <meta name="robots" content="noindex" />}
      <meta
        property="og:image"
        content={
          data.prismicBlogPost.data.seo_og_image?.url ||
          data.prismicBlogPost.data.cover?.url
        }
      />
      <link
        rel="alternate"
        hrefLang={pageContext.lang.slice(0, 2)}
        href={
          pageContext.lang === langs[0]
            ? siteUrl + "/blog/" + pagePath
            : siteUrl + "/" + pageContext.lang + "/blog/" + pagePath
        }
      />
      {data.prismicBlogPost.alternate_languages?.map((alt, i) => {
        return (
          <link
            key={i}
            rel="alternate"
            hrefLang={alt.lang.slice(0, 2)}
            href={
              alt.lang === "en-us"
                ? siteUrl + "/blog/" + alt.uid
                : siteUrl + "/" + pageContext.lang + "/blog/" + alt.uid
            }
          />
        )
      })}
    </Seo>
  )
}

export default BlogPost
