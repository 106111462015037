import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { langs } from "../../prismic-configuration.js"

// const CardExternalLink = (
//   p,
//   webview,
//   external_link,
//   isLarge,
//   category_color,
//   category_name,
//   publication_date
// ) => {
//   return (
//     <>
//       <a
//         href={external_link}
//         target="_blank"
//         rel="noreferrer"
//         className={isLarge ? "lg:h-full" : ""}
//       >
//         <div
//           className={` ${
//             isLarge ? "md:order-last md:w-7/12 lg:flex lg:flex-col " : ""
//           }
//       `}
//         >
//           <GatsbyImage
//             image={getImage(p.card_image) || getImage(p.cover)}
//             alt={p.cover.alt || ""}
//             layout={"fullWidth"}
//             className={`w-full rounded-t-xl lg:flex-1 h-44
//              ${isLarge ? "lg:min-h-[calc(100%-48px)]" : ""}
//              ${webview ? " h-28" : ""}`}
//             css={!isLarge && cssCover}
//           />
//           <div
//             className={`text-base uppercase rounded-b-xl px-6 py-3 font-montserrat font-extrabold text-white`}
//             style={{ background: category_color }}
//           >
//             {category_name}
//           </div>
//         </div>
//         <div
//           className={`px-8 first-letter ${webview ? "px-6" : ""}${
//             isLarge ? "md:order-first md:w-5/12" : ""
//           } `}
//         >
//           <h3
//             className={`py-3 text-xl font-montserrat uppercase font-extrabold md:pt-5 ${
//               webview ? "text-sm" : ""
//             } md:text-2xl${isLarge ? "md:pt-20 lg:pt-32" : ""} `}
//             dangerouslySetInnerHTML={{
//               __html: p.title.text,
//             }}
//           />
//           {publication_date.en && publication_date.fr && (
//             <span className="text-neutral-500 block mb-3 text-sm">
//               {post.lang === "en-us" && publication_date.en}
//               {post.lang === "fr-fr" && publication_date.fr}
//             </span>
//           )}
//           {!webview && p.structured_text?.text && (
//             <>
//               <p
//                 className="pb-1 pl-4 font-sans text-sm text-pocket-text
//             md:pt-2 md:pl-0"
//                 dangerouslySetInnerHTML={{
//                   __html:
//                     p.structured_text?.text?.substring(0, excerpt) + " ...",
//                 }}
//               />
//               <span
//                 className={` font-bold text-base underline `}
//                 style={{ color: category_color }}
//               >
//                 {globals.data.read_more}
//               </span>
//             </>
//           )}
//         </div>
//       </a>
//     </>
//   )
// }

const CardLink = ({ to, href, className, children }) => {
  if (href) {
    return (
      <a href={href} className={className} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    )
  }
}

const CardBlogPost = ({
  post = {},
  isLarge = false,
  globals,
  publication_date = {},
  webview = false,
}) => {
  const p = post.data
  const excerpt = isLarge ? 150 : 50
  const urlPrefixLang = post.lang === langs[0] ? "/" : `/${post.lang}/`
  const { category_name = "News", category_color = "#FFBF04" } =
    p.category?.document?.data || {}
  const urlPost =
    urlPrefixLang + (webview ? "webview/" : "") + "blog/" + post.uid

  return (
    <article
      key={post.id}
      className={`flex flex-col mb-12 rounded-xl shadow-lg shadow-gray-100 bg-white 
      ${isLarge ? "md:flex-row lg:min-h-[450px]" : ""} ${
        webview ? " mb-8" : ""
      } md:text-2xl md:shadow-2xl md:shadow-gray-200 
      lg:text-3xl`}
    >
      <div
        className={` ${
          isLarge ? "md:order-last md:w-7/12 lg:flex lg:flex-col " : ""
        }
      `}
      >
        <CardLink
          to={urlPost}
          href={p.external_link}
          className={isLarge ? "lg:h-full" : ""}
        >
          <GatsbyImage
            image={getImage(p.card_image) || getImage(p.cover)}
            alt={p.cover.alt || ""}
            layout={"fullWidth"}
            className={`w-full rounded-t-xl lg:flex-1 h-44 
            ${isLarge ? "lg:min-h-[calc(100%-48px)]" : ""}
            ${webview ? " h-28" : ""}`}
            css={!isLarge && cssCover}
          />
          <div
            className={`text-base uppercase rounded-b-xl px-6 py-3 font-montserrat font-extrabold text-white`}
            style={{ background: category_color }}
          >
            {category_name}
          </div>
        </CardLink>
      </div>
      <div
        className={`px-8 first-letter ${webview ? "px-6" : ""}${
          isLarge ? "md:order-first md:w-5/12" : ""
        } `}
      >
        <CardLink to={urlPost} href={p.external_link}>
          <h3
            className={`py-3 text-xl font-montserrat uppercase font-extrabold md:pt-5 ${
              webview ? "text-sm" : ""
            } md:text-2xl${isLarge ? "md:pt-20 lg:pt-32" : ""} `}
            dangerouslySetInnerHTML={{
              __html: p.title.text,
            }}
          />
        </CardLink>
        {publication_date.en && publication_date.fr && (
          <span className="text-neutral-500 block mb-3 text-sm">
            {post.lang === "en-us" && publication_date.en}
            {post.lang === "fr-fr" && publication_date.fr}
          </span>
        )}
        {!webview && p.structured_text?.text && (
          <>
            <p
              className="pb-1 pl-4 font-sans text-sm text-pocket-text
            md:pt-2 md:pl-0"
              dangerouslySetInnerHTML={{
                __html: p.structured_text?.text?.substring(0, excerpt) + " ...",
              }}
            />
            <CardLink
              to={urlPost}
              href={p.external_link}
              className="block w-full text-right pb-3 pl-4"
            >
              <span
                className={` font-bold text-base underline `}
                style={{ color: category_color }}
              >
                {globals.data.read_more}
              </span>
            </CardLink>
          </>
        )}
      </div>
    </article>
  )
}

const cssCover = css`
  /* height: 180px; */
  @media (min-width: 768px) {
    height: 250px;
  }
`

CardBlogPost.propTypes = {
  post: PropTypes.object.isRequired,
  isLarge: PropTypes.bool,
  globals: PropTypes.object.isRequired,
  publication_date: PropTypes.object,
}

export default CardBlogPost
